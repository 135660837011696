import styles from './EventCreator.module.css'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';




const EventCreator = (props) => {
    const [event, setEvent] = useState({ tagId: props.id, event: '' });
    const [selectError, setSelectError] = useState(false);
    const [currentEvents, setCurrentEvents] = useState(props.data);
    const [loaded, setLoaded] = useState(false);
    const [request, setrequest] = useState(false);
    const [start, setStart] = useState(false);
    const [complete, setComplete] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate()


    useEffect(() => {

        currentEvents.forEach((event) => {
            const e = event['event_type']
            if (e === 'loaded') {
                setLoaded(true)
            }
            if (e === 'request') {
                setrequest(true)
            }
            if (e === 'start') {
                setStart(true)
            }
            if (e === 'complete') {
                setComplete(true)
            }

        });

    }, [loaded,request,start,complete]);


    const handleChange = (e) => {

        if (e.target.value !== '') {
            setSelectError(false);
        }

        setEvent({ tagId: props.id, event: e.target.value });


    }


    const save = async (e) => {
        e.preventDefault();
        const error = await errHandling();

        if (error || isSaving) {
            return
        } else {
            setIsSaving(true);
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/add/event`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(event)
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                props.onClose()

            } catch (err) {
                console.log(err);
            }
        }

    }

    const errHandling = async () => {

        let error = false
        if (event.event === '') {
            setSelectError(true);
            error = true;
        }
        return error;
    }


    const backToLayOut = () => {
        props.onClose()
    }


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <Card sx={{ width: 500, height: 300, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '200%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Add Event"
                        sx={{ color: '#289FD2' }}
                    />
                    <CardContent sx={{ width: "inherit" }} >

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">event</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={event.event}
                                label="event"
                                onChange={handleChange}
                                error={selectError}
                            >
                                {!loaded ? <MenuItem value='loaded'>loaded</MenuItem> : null}
                                {!request ? <MenuItem value='request'>request</MenuItem> : null}
                                {!start ? <MenuItem value='start'>start</MenuItem> : null}
                                {!complete ? <MenuItem value='complete'>complete</MenuItem> : null}
                            </Select>
                        </FormControl>

                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <button className={styles.submitButton} onClick={save}>save</button>
                    </CardActions>
                </Card>
            </Backdrop>
        </div>
    )

}

export default EventCreator;