import styles from './NewPublisher.module.css'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';




const NewPublisher = (props) => {

    const [name, setName] = useState('');
    const [tracking, setTracking] = useState('');
    const [nameError, setNameError] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();


    const save = async (e) => {
        e.preventDefault();
        const error = await errHandling();
        const publisher = { name: name, tracking: tracking }

        if (error || isSaving) {
            return
        } else {
            setIsSaving(true);
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/add/publisher`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(publisher)
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                props.onClose();

            } catch (err) {
                console.log(err);
            }
        }

    }

    const errHandling = async () => {

        let error = false
        if (name === '') {
            setNameError(true);
            error = true;
        }
        if (tracking === '') {
            setTrackingError(true)
            error = true;
        }
        return error;
    }

    const backToLayOut = () => {
        props.onClose();
    }


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={true}
        >
            <Card sx={{ width: 500, height: 350, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <CardHeader
                    action={
                        <IconButton sx={{ position: 'relative', left: '150%' }} onClick={backToLayOut}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title="Add New Publisher"
                    sx={{ color: '#289FD2' }}
                />
                <CardContent sx={{ width: "inherit" }} >
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },

                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField error={nameError} type='text' onChange={(e) => { setName(e.target.value); e.target.value !== "" ? setNameError(false) : setNameError(true) }} id="outlined-basic" label="Name" variant="outlined" />
                        <TextField error={trackingError} type='text' onChange={(e) => { setTracking(e.target.value); e.target.value !== "" ? setTrackingError(false) : setTrackingError(true) }} id="outlined-basic" label="Tracking" variant="outlined" />
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <button className={styles.submitButton} onClick={save}>save</button>
                </CardActions>
            </Card>
        </Backdrop>
    )

}

export default NewPublisher