import styles from './ViewTag.module.css'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import config from '../../config';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';

const ViewTag = (props) => {
    const [tagId, setTagId] = useState(props.id)
    const [name, setName] = useState(props.name)
    const [tag, setTag] = useState('')
    const [loading, setLoading] = useState(true);
    const [domains, setDomains] = useState([{ id: 1, name: 'plyair.com' }, { id: 2, name: 'companionaudio.com' }, { id: 3, name: 'myaudioads.com' }]);
    const [domainName, setDomainName] = useState(props.domain)
    const tagText = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/publishers/${name}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if (data) {
                setLoading(false);
                if (data.name === 'peak') {
                    const link = config.tagLink.replace('{ID}', tagId)
                        .replace('{SIGNATURE}', domainName)
                        .replace('{PROTOCOL}', process.env.REACT_APP_API_PROTOCOL)
                        .replace('{TRACKING}', data.tracking).replace('{TRACKING}', data.tracking)
                        .replace('{DOMAINS}', domainName)
                        .replace('{BANNER}', process.env.REACT_APP_API_SHOW_TAG_SCRIPT);
                    setTag(link)
                } else if (data.name === 'lqm') {
                    const link = config.tagLink.replace('{ID}', tagId)
                        .replace('{SIGNATURE}', domainName).replace('{PROTOCOL}', process.env.REACT_APP_API_PROTOCOL)
                        .replace('{TRACKING}', data.tracking)
                        .replace('{DOMAINS}', domainName)
                        .replace('{BANNER}', process.env.REACT_APP_API_SHOW_TAG_SCRIPT);
                    setTag(link)
                } else {
                    const link = config.tagLinkClean.replace('{ID}', tagId)
                        .replace('{SIGNATURE}', domainName)
                        .replace('{PROTOCOL}', process.env.REACT_APP_API_PROTOCOL)
                        .replace('{DOMAINS}', domainName)
                        .replace('{BANNER}', process.env.REACT_APP_API_SHOW_TAG_SCRIPT);
                    setTag(link)
                }
            }
        }
        )()


    }, [tag.setTag, tagId, name, setTagId, setName, setLoading, navigate, domainName, setDomainName])

    const copyLink = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/update/domain`, {
                method: 'Put',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ id: tagId, domain: domainName })
            });
            if (res.status === 403) {
                navigate("/");
            }

            const data = await res.json();
            navigator.clipboard.writeText(tagText.current.innerText);
            props.onClose();
        } catch (err) {
            console.log(err);
        }
        navigator.clipboard.writeText(tagText.current.innerText);
        props.onClose();
    }

    const backToLayOut = (e) => {
        props.onClose();
    }

    const handleChange = (e) => {
        setDomainName(e.target.value);
    }

    return (


        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            {loading ? <CircularProgress /> :
                <Card sx={{ maxWidth: 540, maxHeight: 600, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '250%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={domainName}
                            label="Domain"
                            onChange={handleChange}
                        >
                            {domains.map((domain) => (
                                <MenuItem key={domain.id} value={domain.name}>
                                    {domain.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <CardContent>
                        <div ref={tagText} className={styles.textMargin} type='text' >{tag}</div>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <button className={styles.copyButton} onClick={copyLink}>Save & Copy</button>
                    </CardActions>
                </Card>
            }
        </Backdrop>

    )

}

export default ViewTag;